const apiWebhookIntegrate = async (eventsSelected, webhookUrl) => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/integrations/webhook/integrate`,
      {
        method: "POST",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          events: eventsSelected,
          org_id: org_id,
          webhook_url: webhookUrl,
        }),
      }
    );
    console.log({
      events: eventsSelected,
      org_id: org_id,
      webhook_url: webhookUrl,
    });
    if (!response.ok) {
      throw new Error("Integrating Webhook url failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Integrating Webhook url failed", error);
    throw error;
  }
};

export default apiWebhookIntegrate;
