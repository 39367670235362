import { DailyVideo, useLocalSessionId } from "@daily-co/daily-react";

export default function ClientsCamera() {
  const localSessionId = useLocalSessionId();

  return (
    localSessionId && (
      <div className="relative w-auto h-auto rounded-md border border-gray-100 shadow-lg shadow-gray-200">
        <div className="aspect-w-16 aspect-h-9">
          <DailyVideo
            type={"video"}
            fit="contain"
            sessionId={localSessionId}
            className="object-contain w-full h-full"
          />
        </div>
      </div>
    )
  );
}
