// import React, { createContext, useEffect, useState, useRef } from "react";
// import Sidebar from "../ui/Sidebar";
// import {
//   Outlet,
//   useNavigate,
//   useLocation,
//   useSearchParams,
// } from "react-router-dom";
// import apiCheckForCalling from "../Signup/apiCheckForCalling";
// import apiDeclineCall from "../Signup/apiDeclineCall";
// import { OrgSelector } from "../SuperAdmin/OrgSelector";

// export const AppLayoutContext = createContext();

// function AppLayout() {
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [sidebarHovered, setSidebarHovered] = useState(false);
//   const [pendingRequests, setPendingRequests] = useState([]);
//   const [debounce, setDebounce] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [showSoundPrompt, setShowSoundPrompt] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [searchParams] = useSearchParams();
//   const audioRef = useRef(null);

//   useEffect(() => {
//     const checkMobile = () => {
//       setIsMobile(window.innerWidth < 1024);
//     };
//     checkMobile();
//     window.addEventListener("resize", checkMobile);
//     return () => window.removeEventListener("resize", checkMobile);
//   }, []);

//   const fetchCallingStatus = async () => {
//     try {
//       const data = await apiCheckForCalling();
//       setPendingRequests(data.call_requests);

//       if (data.call_requests.length > 0 && !isPlaying && !debounce) {
//         await playNotificationSound();
//       } else if (data.call_requests.length === 0 && isPlaying) {
//         stopNotificationSound();
//       }
//     } catch (error) {
//       console.error("Error fetching calling status:", error);
//       if (isPlaying) {
//         stopNotificationSound();
//       }
//     }
//   };

//   useEffect(() => {
//     const intervalId = setInterval(fetchCallingStatus, 3000);
//     return () => clearInterval(intervalId);
//   }, [isPlaying, debounce]);

//   useEffect(() => {
//     if (searchParams.get("callRequest") !== "true") {
//       fetchCallingStatus();
//     }
//   }, [location]);

//   const playNotificationSound = async () => {
//     if (audioRef.current && !isPlaying) {
//       try {
//         audioRef.current.loop = true;
//         await audioRef.current.play();
//         setIsPlaying(true);
//         setShowSoundPrompt(false);
//       } catch (error) {
//         console.error("Error playing audio:", error);
//         setShowSoundPrompt(true);
//       }
//     }
//   };

//   const stopNotificationSound = () => {
//     if (audioRef.current && isPlaying) {
//       audioRef.current.pause();
//       audioRef.current.currentTime = 0;
//       setIsPlaying(false);
//     }
//   };

//   const acceptCall = async () => {
//     if (pendingRequests.length > 0) {
//       const acceptedRequest = pendingRequests[0];
//       const currentPath = window.location.pathname;
//       setPendingRequests((prevRequests) => prevRequests.slice(1));
//       stopNotificationSound();
//       if (currentPath.includes(`/visitors/${acceptedRequest.visitor_id}`)) {
//         const timestamp = new Date().getTime();
//         navigate(
//           `/visitors/${acceptedRequest.visitor_id}?callRequest=true&refresh=${timestamp}`
//         );
//       } else {
//         navigate(`/visitors/${acceptedRequest.visitor_id}?callRequest=true`);
//       }
//       setDebounce(true);
//       setTimeout(() => setDebounce(false), 4000);
//     }
//   };

//   const declineCall = async () => {
//     if (pendingRequests.length > 0) {
//       try {
//         const declinedRequest = pendingRequests[0];
//         setPendingRequests((prevRequests) => prevRequests.slice(1));
//         if (pendingRequests.length === 1) {
//           stopNotificationSound();
//         }
//         await apiDeclineCall(declinedRequest.visitor_id);
//       } catch (error) {
//         console.error("Error declining call:", error);
//       }
//     }
//   };

//   const handleSoundPrompt = () => {
//     playNotificationSound();
//   };

//   const isDevMode = localStorage.getItem("devmode") === "true";

//   return (
//     <AppLayoutContext.Provider
//       value={{
//         sidebarOpen,
//         setSidebarOpen,
//         sidebarHovered,
//         setSidebarHovered,
//         isMobile,
//       }}
//     >
//       <div className="flex h-dvh">
//         <Sidebar />
//         <div className="flex overflow-hidden flex-col flex-1">
//           <main className="overflow-auto flex-1 bg-gray-100">
//             <div className="p-6">
//               {showSoundPrompt && (
//                 <div className="flex fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-50">
//                   <div className="p-6 bg-white rounded-lg shadow-xl">
//                     <p className="mb-4 text-lg">You have a new call request!</p>
//                     <button
//                       onClick={handleSoundPrompt}
//                       className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
//                     >
//                       Play Notification Sound
//                     </button>
//                   </div>
//                 </div>
//               )}
//               {!debounce && pendingRequests.length > 0 && (
//                 <div className="flex justify-between items-center p-4 mb-4 w-full text-center text-white bg-green-800 rounded-lg">
//                   <p>
//                     Visitor {pendingRequests[0].visitor_name} is trying to call
//                     you!
//                   </p>
//                   <div className="flex gap-4">
//                     <button
//                       className="p-4 bg-green-500 rounded-lg"
//                       onClick={acceptCall}
//                     >
//                       Accept
//                     </button>
//                     <button
//                       className="p-4 bg-red-500 rounded-lg"
//                       onClick={declineCall}
//                     >
//                       Decline
//                     </button>
//                   </div>
//                 </div>
//               )}
//               {isDevMode && (
//                 <div className="py-4 mb-4 w-full border-b-2">
//                   <OrgSelector />
//                 </div>
//               )}
//               <Outlet />
//             </div>
//           </main>
//         </div>
//       </div>
//       <audio ref={audioRef} src="/sounds/F2F-RINGTONE.MP3" />
//     </AppLayoutContext.Provider>
//   );
// }

// export default AppLayout;

import React, {
  createContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Sidebar from "../ui/Sidebar";
import {
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import apiCheckForCalling from "../Signup/apiCheckForCalling";
import apiDeclineCall from "../Signup/apiDeclineCall";
import { OrgSelector } from "../SuperAdmin/OrgSelector";
import apiSlackAgentAction from "../Utils/apiSlackAgentAction";

export const AppLayoutContext = createContext();

function AppLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarHovered, setSidebarHovered] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [debounce, setDebounce] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const audioRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const [declinedRequests, setDeclinedRequests] = useState(new Set());

  const fetchCallingStatus = useCallback(async () => {
    try {
      const data = await apiCheckForCalling();
      // Filter out declined requests
      const filteredRequests = data.call_requests.filter(
        (request) => !declinedRequests.has(request.visitor_id)
      );
      setPendingRequests(filteredRequests);

      if (filteredRequests.length > 0 && !isPlaying && !debounce) {
        await playNotificationSound();
      } else if (filteredRequests.length === 0 && isPlaying) {
        stopNotificationSound();
      }
    } catch (error) {
      console.error("Error fetching calling status:", error);
      if (isPlaying) {
        stopNotificationSound();
      }
    }
  }, [isPlaying, debounce, declinedRequests]);

  useEffect(() => {
    const intervalId = setInterval(fetchCallingStatus, 5000); // Increased interval
    return () => clearInterval(intervalId);
  }, [fetchCallingStatus]);

  useEffect(() => {
    if (searchParams.get("callRequest") !== "true") {
      fetchCallingStatus();
    }
  }, [location, fetchCallingStatus, searchParams]);

  const playNotificationSound = useCallback(async () => {
    if (audioRef.current && !isPlaying) {
      try {
        audioRef.current.loop = true;
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
  }, [isPlaying]);

  const stopNotificationSound = useCallback(() => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [isPlaying]);

  const acceptCall = useCallback(async () => {
    if (pendingRequests.length > 0) {
      const acceptedRequest = pendingRequests[0];

      apiSlackAgentAction("agent_accepted", acceptedRequest.visitor_id);

      const currentPath = window.location.pathname;
      setPendingRequests((prevRequests) => prevRequests.slice(1));
      stopNotificationSound();
      if (currentPath.includes(`/visitors/${acceptedRequest.visitor_id}`)) {
        const timestamp = new Date().getTime();
        navigate(
          `/visitors/${acceptedRequest.visitor_id}?callRequest=true&refresh=${timestamp}`
        );
      } else {
        navigate(`/visitors/${acceptedRequest.visitor_id}?callRequest=true`);
      }
      setDebounce(true);
      setTimeout(() => setDebounce(false), 4000);
    }
  }, [pendingRequests, navigate, stopNotificationSound]);

  const declineCall = useCallback(async () => {
    if (pendingRequests.length > 0) {
      try {
        const declinedRequest = pendingRequests[0];

        apiSlackAgentAction("agent_declined", declinedRequest.visitor_id);

        setDeclinedRequests((prev) =>
          new Set(prev).add(declinedRequest.visitor_id)
        );
        setPendingRequests((prevRequests) =>
          prevRequests.filter(
            (request) => request.visitor_id !== declinedRequest.visitor_id
          )
        );

        stopNotificationSound();
        setDebounce(true);

        await apiDeclineCall(declinedRequest.visitor_id);

        setTimeout(() => setDebounce(false), 3000);
        // Optional: Remove from declinedRequests after 10s to allow re-fetch
        setTimeout(() => {
          setDeclinedRequests((prev) => {
            const updated = new Set(prev);
            updated.delete(declinedRequest.visitor_id);
            return updated;
          });
        }, 10000);
      } catch (error) {
        console.error("Error declining call:", error);
        setDebounce(false);
      }
    }
  }, [pendingRequests, stopNotificationSound]);

  const handleSoundPrompt = useCallback(() => {
    playNotificationSound();
  }, [playNotificationSound]);

  const isDevMode = localStorage.getItem("devmode") === "true";

  const contextValue = useMemo(
    () => ({
      sidebarOpen,
      setSidebarOpen,
      sidebarHovered,
      setSidebarHovered,
      isMobile,
    }),
    [sidebarOpen, sidebarHovered, isMobile]
  );

  return (
    <AppLayoutContext.Provider value={contextValue}>
      <div className="flex h-dvh">
        <Sidebar />
        <div className="flex overflow-hidden flex-col flex-1">
          <main className="overflow-auto flex-1 bg-gray-100">
            <div className="p-6">
              {!debounce && pendingRequests.length > 0 && (
                <div className="flex justify-between items-center p-4 mb-4 w-full text-center text-white bg-green-800 rounded-lg">
                  <p>
                    Visitor {pendingRequests[0].visitor_name} is trying to call
                    you!
                  </p>
                  <div className="flex gap-4">
                    <button
                      className="p-4 bg-green-500 rounded-lg"
                      onClick={acceptCall}
                    >
                      Accept
                    </button>
                    <button
                      className="p-4 bg-red-500 rounded-lg"
                      onClick={declineCall}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              )}
              {isDevMode && (
                <div className="py-4 mb-4 w-full border-b-2">
                  <OrgSelector />
                </div>
              )}
              <Outlet />
            </div>
          </main>
        </div>
      </div>
      <audio ref={audioRef} src="/sounds/F2F-RINGTONE.MP3" />
    </AppLayoutContext.Provider>
  );
}

export default AppLayout;
