import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/tooltip";

export function VideoControlButton({
  onClick,
  tooltipText,
  active,
  children,
  notEndCall,
}) {
  const formatTooltipText = (text) => {
    const words = text.split(" ");
    if (words.length <= 2) return text;

    return (
      <>
        <span className="inline-block">{`${words[0]} ${words[1]}`}</span>
        <span className="block">{words.slice(2).join(" ")}</span>
      </>
    );
  };

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            onClick={onClick}
            type="button"
            className={`relative p-2 ${
              notEndCall && "w-[40px] flex justify-center items-center"
            } rounded-lg text-gray-500 bg-gray-50 transition-colors ${
              active && "text-primary bg-stone-200"
            }`}
          >
            {children}
          </button>
        </TooltipTrigger>
        <TooltipContent
          className="bg-secondary text-secondary-foreground translate-x-[60px] ml-2 text-center"
          side="top"
          sideOffset={2}
        >
          <p className="text-sm font-medium whitespace-normal">
            {formatTooltipText(tooltipText)}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
