import React, { useCallback, useEffect, useState } from "react";
import { useF2FContext } from "../F2FContext";
import { useDaily } from "@daily-co/daily-react";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import BeforeF2F from "./BeforeF2F";
import VisitorsScrShare from "./TileComponents/VisitorsScrShare";
import DuringF2F from "./DuringF2F";
import apiSlackAgentAction from "../../Utils/apiSlackAgentAction";

export default function CallingWindow() {
  const {
    visitorId,
    setRoomUrl,
    callAccepted,
    setCallAccepted,
    setSocket,
    screenShareOn,
    setIsScreenSharing,
    showCallUI,
    setshowCallUI,
    watchUrl,
    STATE_CREATING,
    STATE_IDLE,
  } = useF2FContext();
  const callObject = useDaily();

  const [appState, setAppState] = useState(STATE_IDLE);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const callRequest = queryParams.get("callRequest");

  const handleCallResponse = useCallback(
    debounce((data) => {
      if (!data.call_response) {
        if (callObject) {
          setRoomUrl(null);
        }
        setAppState(STATE_IDLE);
        setshowCallUI(() => false);
        toast.error("Call declined!");
      }
    }, 500),
    [callObject, setRoomUrl, setAppState, setshowCallUI]
  );

  const handleCallEnded = useCallback(
    debounce(() => {
      if (callObject) {
        setRoomUrl(null);
      }
      setAppState(STATE_IDLE);
      setCallAccepted(false);
      setshowCallUI(() => false);
      toast.success("Call ended!");
      queryParams.delete("callRequest");
      const newQueryString = queryParams.toString();
      const newUrl = newQueryString
        ? `${window.location.pathname}?${newQueryString}`
        : window.location.pathname;

      window.history.replaceState(null, "", newUrl);
    }, 500),
    [
      callObject,
      setRoomUrl,
      setAppState,
      setCallAccepted,
      setshowCallUI,
      queryParams,
    ]
  );

  const handleJoinCall = useCallback(
    debounce((data) => {
      setAppState(STATE_CREATING);
      if (data.room_url) {
        if (callObject) {
          void callObject.join({ url: data.room_url });
        }
        setRoomUrl(data.room_url);

        apiSlackAgentAction("connected", visitorId);

        setCallAccepted(true);
        let currentUrl = window.location.href;

        // Create a new URL object
        let url = new URL(currentUrl);

        // Append or update the parameter
        url.searchParams.set("callRequest", "true");

        // Update the browser's address bar (optional, without reloading)
        window.history.replaceState(null, "", url.href);
      } else {
        console.error("No room URL provided.");
        setAppState(STATE_IDLE);
      }
    }, 500),
    [callObject, setAppState, setRoomUrl, setCallAccepted]
  );

  useEffect(() => {
    const roomId = visitorId;

    const newSocket = io(process.env.REACT_APP_API_URL, {
      query: { target_id: roomId },
    });

    setSocket(newSocket);

    newSocket.on("f2f_call_response", handleCallResponse);
    newSocket.on("f2f_call_ended", handleCallEnded);
    newSocket.on("join_f2f_call", handleJoinCall);
    newSocket.on("error", (data) => {
      console.log(data.message);
    });

    newSocket.on("screen_share_on", (data) => {
      if (data.f2fVisitorID === visitorId && data.userType === "agent") {
        setIsScreenSharing(true);
      }
    });

    newSocket.on("screen_share_off", (data) => {
      if (data.f2fVisitorID === visitorId && data.userType === "agent") {
        setIsScreenSharing(false);
      }
    });

    if (callRequest) {
      newSocket.emit("start_f2f_call", {
        agentName: localStorage.getItem("agentName"),
        f2fVisitorID: visitorId,
        agentId: localStorage.getItem("userid"),
      });

      setshowCallUI(() => true);
    }

    return () => {
      newSocket.off("f2f_call_response");
      newSocket.off("f2f_call_ended");
      newSocket.off("join_f2f_call");
      newSocket.off("error");
      newSocket.off("screen_share_on");
      newSocket.off("screen_share_off");
    };
  }, [location.search]);

  return (
    <div className="relative rounded-3xl shadow-md">
      {watchUrl && (
        <>
          <div
            className={`${screenShareOn && "hidden"} relative w-full`}
            style={{ paddingTop: "56.25%" }}
          >
            <div
              className="absolute inset-0 bg-center bg-cover"
              style={{
                backgroundImage: "url('/photos/bg-2.png')",
              }}
            >
              {console.log("Rendering iframe with watchUrl:", watchUrl)}
              <iframe
                src={watchUrl}
                title="mainScreen"
                className="absolute inset-0 w-full h-full"
                sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox allow-top-navigation"
              />

              {!screenShareOn && !callAccepted && (
                <div
                  className="absolute inset-0 bg-transparent"
                  style={{ zIndex: 10 }}
                />
              )}
            </div>
          </div>

          {screenShareOn && <VisitorsScrShare />}
        </>
      )}
      <div className="bg-white rounded-br-lg rounded-bl-lg">
        {showCallUI ? <DuringF2F setAppState={setAppState} /> : <BeforeF2F />}
      </div>
    </div>
  );
}
