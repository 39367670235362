// function Logo({ small }) {
//   return (
//     <div
//       className={`flex items-center ${
//         small ? "justify-center" : "justify-start"
//       }`}
//     >
//       <img
//         src="/logo/logo.png"
//         alt="Logo"
//         className={`${small ? "w-8 h-8" : "w-12 h-12"}`}
//       />
//     </div>
//   );
// }

// export default Logo;

import React, { useState, useEffect } from "react";

function Logo({ small }) {
  const [isAdmin, setIsAdmin] = useState(() => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("devmode") === "true";
    }
    return false;
  });
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    const adminKey = localStorage.getItem("adminkey");
    if (adminKey) {
      setShowToggle(true);
      const currentDevMode = localStorage.getItem("devmode") === "true";
      setIsAdmin(currentDevMode);
    }
  }, []);

  const handleToggle = () => {
    if (!showToggle) return;

    const apiKey = localStorage.getItem("apikey");
    const adminKey = localStorage.getItem("adminkey");
    const adminOrg = localStorage.getItem("adminorg");

    if (isAdmin) {
      // Switching from admin to non-admin
      localStorage.setItem("apikey", adminKey);
      localStorage.setItem("adminkey", apiKey);
      localStorage.setItem("orgid", adminOrg);
      localStorage.setItem("devmode", "false");
    } else {
      // Switching from non-admin to admin
      localStorage.setItem("adminkey", apiKey);
      localStorage.setItem("apikey", adminKey);
      localStorage.setItem("devmode", "true");
    }

    setIsAdmin(!isAdmin);
  };

  return (
    <div
      className={`flex items-center h-14 ${
        small ? "justify-center" : "justify-start"
      }`}
      onClick={handleToggle}
      style={{ cursor: showToggle ? "pointer" : "default" }}
    >
      <img
        src="/logo/F2FLogoXmasB.png"
        alt="Logo"
        className={`${small ? "w-10 h-10" : "w-14 h-14"} ${
          isAdmin ? "border-2 border-red-500" : ""}`}
      />
      {!small && showToggle && (
        <span
          className={`ml-2 font-medium ${
            isAdmin ? "text-red-500" : "text-gray-700"
          }`}
        ></span>
      )}
    </div>
  );
}

export default Logo;
