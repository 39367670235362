import React, { useState, useEffect } from "react";
import { Button } from "../components/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/dropdown-menu";
import apiAllOrgs from "../SuperAdmin/apiAllOrgs";

export function OrgSelector() {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const devMode = localStorage.getItem("devmode") === "true";
      if (devMode) {
        try {
          const allOrgs = await apiAllOrgs();
          console.log(allOrgs);
          setOrganizations(allOrgs.organizations);
        } catch (error) {
          console.error("Failed to fetch organizations:", error);
        }
      }
    };

    fetchOrganizations();
  }, []);

  const handleOrgSelect = (org) => {
    localStorage.setItem("orgid", org._id);
    setSelectedOrg(org.name);
    // Optionally, you can reload the page or update the app state here
    // window.location.reload()
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          {selectedOrg || "Select Organization"}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="overflow-y-scroll ml-6 w-56 h-80">
        {organizations.map((org) => (
          <DropdownMenuItem key={org._id} onSelect={() => handleOrgSelect(org)}>
            {org.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
