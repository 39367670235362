import { LuStopCircle } from "react-icons/lu";
import { useF2FContext } from "../F2FContext";
import apiSlackAgentAction from "../../Utils/apiSlackAgentAction";

export default function BeforeF2F() {
  const { visitorId, setStalking, setshowCallUI, socket } = useF2FContext();

  const userid = localStorage.getItem("userid");
  const agentName = localStorage.getItem("agentName");

  const handleConnect = () => {
    socket.emit("f2f_call_request", {
      agentName: agentName,
      f2fVisitorID: visitorId,
      user_id: userid,
    });
    setshowCallUI(() => true);

    apiSlackAgentAction("attempted", visitorId);
  };

  return (
    <div className="flex flex-col gap-4 min-[330px]:flex-row items-center justify-between min-[500px]:h-16  px-4 py-2 min-[500px]:py-0 ">
      <button
        className="border-0 text-black font-semibold bg-green-300 px-2 py-[0.7rem] rounded-lg hover:bg-green-400 "
        onClick={handleConnect}
      >
        <p>Talk Face-to-Face 👋</p>
      </button>
      <button
        className="border-0 text-black text-2xl font-semibold bg-red-300 px-3 py-[0.7rem] rounded-lg hover:bg-red-400"
        onClick={() => setStalking(false)}
      >
        {<LuStopCircle />}
      </button>
    </div>
  );
}
