import React from "react";
import SignUpForm from "../ui/SignUpForm";

const WaitListPage = () => {
  return (
    <SignUpForm isWaitList={true}>
      <div className="flex overflow-auto justify-start h-full">
        <div className="w-full h-auto">
          <div className="relative w-full h-full">
            <iframe
              src="https://app2.face2face.io/waitlist"
              className="absolute inset-0 w-full h-full"
              frameBorder="0"
              allowFullScreen
              title="waitlist"
            />
          </div>
        </div>
      </div>
    </SignUpForm>
  );
};

export default WaitListPage;
