import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { init as initAmplitude } from "@amplitude/analytics-browser";

initAmplitude("67c8050e3434ee669dfb3740e3b756a");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="f2f-dev.us.auth0.com"
      clientId="UF5emZ0ydtEHp8gaxntZ4QnVFNmti4eq"
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: "http://localhost:3000/dashboard",
        audience: "localhost:5000",
        scope: "read:current_user profile email",
        prompt: "consent",
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
