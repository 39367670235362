const apiCallHistory = async (page, count, response, type) => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    const responseData = await fetch(
      process.env.REACT_APP_API_URL +
        `/organizations/${org_id}/call_requests/call_history?page=${page}&count=${count}&response=${response}&type=${type}`,
      {
        method: "GET",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
        },
      }
    );

    if (!responseData.ok) {
      throw new Error("Fetching calling history failed");
    }

    const data = await responseData.json();
    console.log(data);
    return data; // Return fetched data
  } catch (error) {
    console.error("Fetching calling history failed:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export default apiCallHistory;
