import React, { createContext, useState, useContext, useEffect } from "react";
import startStalkingSession from "./F2FApis.js/startStalkingSession";

const F2FContext = createContext();

export const useF2FContext = () => {
  const context = useContext(F2FContext);
  if (!context) {
    throw new Error("useF2FContext must be used within an F2FProvider");
  }
  return context;
};

export const F2FProvider = ({ children, visitorData }) => {
  const STATE_IDLE = "STATE_IDLE";
  const STATE_CREATING = "STATE_CREATING";
  const STATE_LEAVING = "STATE_LEAVING";

  const {
    _id: visitorId,
    upscope_short_id: visitorUpscopeShortId,
    online,
  } = visitorData.visitor;

  const [stalking, setStalking] = useState(true);
  const [watchUrl, setWatchUrl] = useState();
  const [roomUrl, setRoomUrl] = useState("");

  const [showCallUI, setshowCallUI] = useState(false);

  const [callAccepted, setCallAccepted] = useState(false);
  const [socket, setSocket] = useState(null);
  const [screenShareOn, setScreenShareOn] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);

  useEffect(() => {
    if (online) {
      startStalkingSession(visitorUpscopeShortId, setWatchUrl, setshowCallUI);
    }
  }, []);

  const value = {
    visitorId,
    visitorUpscopeShortId,
    online,
    stalking,
    setStalking,
    watchUrl,
    roomUrl,
    setRoomUrl,
    isScreenSharing,
    setIsScreenSharing,
    screenShareOn,
    setScreenShareOn,
    socket,
    setSocket,
    callAccepted,
    setCallAccepted,
    showCallUI,
    setshowCallUI,
    STATE_IDLE,
    STATE_CREATING,
    STATE_LEAVING,
  };

  return <F2FContext.Provider value={value}>{children}</F2FContext.Provider>;
};
