import { DailyVideo, useParticipantIds } from "@daily-co/daily-react";

import React from "react";

export default function VisitorsCamera() {
  const remoteParticipantIds = useParticipantIds({ filter: "remote" });
  return (
    remoteParticipantIds.length > 0 &&
    remoteParticipantIds.map((id) => (
      <React.Fragment key={id}>
        <div className="relative w-auto h-auto rounded-md border border-gray-100 shadow-lg shadow-gray-200">
          <div className="aspect-w-16 aspect-h-9">
            <DailyVideo
              type={"video"}
              fit="contain"
              sessionId={id}
              className="object-contain w-full h-full"
            />
          </div>
        </div>
      </React.Fragment>
    ))
  );
}
