import { useF2FContext } from "../F2FContext";

export default function StalkingOff() {
  const { setStalking } = useF2FContext();

  const toggleStalking = () => {
    setStalking((prev) => !prev);
  };

  return (
    <div className="bg-white rounded-lg">
      <button
        className="px-6 py-2 m-3 bg-gray-200 rounded-3xl border border-transparent hover:border-black active:border-black"
        onClick={toggleStalking}
      >
        <p>{"Activate stalking 👀"}</p>
      </button>
    </div>
  );
}
