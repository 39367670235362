import { useDaily, DailyAudio } from "@daily-co/daily-react";
import { useF2FContext } from "../F2FContext";
import ClientsCamera from "./TileComponents/ClientsCamera";
import VisitorsCamera from "./TileComponents/VisitorsCamera";
import VisitorsScrShare from "./TileComponents/VisitorsScrShare";
import Tray from "./Tray";

export default function DuringF2F({ setAppState }) {
  const {
    setRoomUrl,
    STATE_LEAVING,
    isScreenSharing,
    socket,
    STATE_IDLE,
    visitorId,
  } = useF2FContext();

  const callObject = useDaily();

  function startLeavingCall() {
    if (!callObject) {
      setRoomUrl(null);
      return;
    }
    setAppState(() => STATE_LEAVING);
    if (isScreenSharing) {
      socket.emit("screen_share_off", {
        f2fVisitorID: visitorId,
        userType: "agent",
      });
    }
    callObject.leave().then(() => {
      setRoomUrl(null);
      setAppState(STATE_IDLE);
    });
    setRoomUrl(null);

    socket.emit("f2f_call_ended", {
      agentName: localStorage.getItem("agentName"),
      user_id: visitorId,
    });
  }

  return (
    <div className="flex flex-col gap-1 p-4">
      <div className="grid grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-3">
        <ClientsCamera />
        <VisitorsCamera />
        <VisitorsScrShare />
      </div>

      <Tray startLeavingCall={startLeavingCall} />
      <DailyAudio />
    </div>
  );
}
