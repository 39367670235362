import { useState, useEffect, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Button } from "../components/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/table";
import { ChevronLeft, ChevronRight, AlertCircle } from "lucide-react";
import Spinner from "../ui/Spinner";
import apiCallHistory from "../CallHistory/apiCallHistory";
import { AppLayoutContext } from "./AppLayout";
import { RxHamburgerMenu } from "react-icons/rx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/select";

const ITEMS_PER_PAGE = 50;

const getColumnsConfig = () => [
  { key: "type", label: "Inbound/Outbound" },
  { key: "status", label: "Status/Duration" },
  { key: "visitor_id", label: "Visitor" },
  { key: "agent", label: "Agent" },
];

const formatTimeAgo = (date) => {
  const now = new Date();
  const diff = now - new Date(date);
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
  if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  return "Just now";
};

export default function CallHistory() {
  const { setSidebarOpen } = useContext(AppLayoutContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [response, setResponse] = useState("all");
  const [type, setType] = useState("all");

  const { data, isLoading, error } = useQuery(
    ["history", currentPage, ITEMS_PER_PAGE, response, type],
    () =>
      apiCallHistory(
        currentPage,
        ITEMS_PER_PAGE,
        response === "all" ? "" : response,
        type === "all" ? "" : type
      ),
    {
      keepPreviousData: true,
    }
  );

  const totalPages = Math.ceil(data?.total / ITEMS_PER_PAGE);
  const columnsConfig = getColumnsConfig();

  useEffect(() => {
    setCurrentPage(1);
  }, [response, type]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= (totalPages || 1)) {
      setCurrentPage(newPage);
    }
  };

  if (isLoading) return <Spinner />;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-2xl font-bold pointer-events-none">Call History</p>
          {data?.total > 0 && <p> {data?.total} Calls</p>}
        </div>
        <button
          className="flex justify-center items-center w-10 h-10 text-black bg-white rounded-full border border-transparent hover:border-black active:border-black lg:hidden"
          onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
        >
          <RxHamburgerMenu />
        </button>
      </div>

      <div className="flex mb-4 space-x-4">
        <Select value={response} onValueChange={setResponse}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select response" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All</SelectItem>
            <SelectItem value="accepted">Accepted</SelectItem>
            <SelectItem value="declined">Declined</SelectItem>
            <SelectItem value="missed">Missed</SelectItem>
          </SelectContent>
        </Select>

        <Select value={type} onValueChange={setType}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All</SelectItem>
            <SelectItem value="inbound">Inbound</SelectItem>
            <SelectItem value="outbound">Outbound</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {data?.results.length > 0 ? (
        <Table className="bg-white">
          <TableHeader>
            <TableRow>
              {columnsConfig.map((column) => (
                <TableHead key={column.key}>{column.label}</TableHead>
              ))}
            </TableRow>
          </TableHeader>

          {data?.results.map((callData) => {
            console.log(callData);
          })}
          <TableBody>
            {data?.results.map((callData) => (
              <TableRow key={callData.id}>
                <TableCell>{`${callData.type} • ${formatTimeAgo(
                  callData.created_at
                )}`}</TableCell>
                <TableCell>{callData.status}</TableCell>
                <TableCell>
                  {callData.visitor_name || callData.visitor_id}
                </TableCell>
                <TableCell>
                  {callData.user_name || callData.accepted_by}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className="flex flex-col justify-center items-center p-8 bg-gray-100 rounded-lg">
          <AlertCircle className="mb-4 w-12 h-12 text-gray-400" />
          <p className="text-xl font-semibold text-gray-700">
            No data available
          </p>
        </div>
      )}

      <div className="flex justify-end items-center py-4 space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <ChevronLeft className="w-4 h-4" />
        </Button>
        <div className="text-sm text-muted-foreground">
          Page {currentPage} of {totalPages}
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <ChevronRight className="w-4 h-4" />
        </Button>
      </div>
    </>
  );
}
