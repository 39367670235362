const apiPopups = async () => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/${org_id}/popups/`,
      {
        method: "GET",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Fetching organization popups failed");
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching organization popups:", error);
    throw error;
  }
};

export default apiPopups;
