export default async function startStalkingSession(
  visitorUpscopeShortId,
  setWatchUrl
) {
  const orgid = localStorage.getItem("orgid");
  const userid = localStorage.getItem("userid");
  const apikey = localStorage.getItem("apikey");
  const agentName = localStorage.getItem("agentName");

  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/upscope/start-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": apikey,
          "X-Org-Id": orgid,
          "X-User-Id": userid,
        },
        body: JSON.stringify({
          visitorId: visitorUpscopeShortId,
          agentName: agentName,
        }),
      }
    );

    const result = await response.json();

    setWatchUrl(result.watch_url);

    console.log("Data fetched:", result);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
