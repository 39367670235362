import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/card";
import { Input } from "../../components/input";
import { Button } from "../../components/button";
import { Label } from "../../components/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/select";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import apiWebhookEvents from "./ApiWebhookEvents";
import apiWebhookIntegrate from "./ApiWebhookIntegrate";
import apiOrganization from "../../Dashborad/apiOrganization";
import apiWebhookTest from "./ApiWebhookTest";
import toast from "react-hot-toast";

export function WebhookIntegration() {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [intergrated, setIntergrated] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function fetchEvents() {
      try {
        const data = await apiWebhookEvents();

        setEvents([...data.events]);
      } catch (error) {
        console.error("Error while fetching events:", error);
      }
    }

    async function fetchOrgData() {
      try {
        const orgData = await apiOrganization();
        if (orgData.organization.integrations.webhooks) {
          setIntergrated(true);
        }
      } catch (error) {
        console.error("Error while fetching org Data:", error);
      }
    }

    fetchEvents();
    fetchOrgData();
  }, []);

  const handleSave = async () => {
    const response = await apiWebhookIntegrate([selectedEvent], webhookUrl);
    if (response.message === "Webhook URL saved successfully") {
      toast.success("Webhook URL saved successfully");
    }
  };

  const handleTest = async () => {
    const response = await apiWebhookTest();
    if (response.message === "Webhook test successful") {
      toast.success("Webhook test successful ✅");
    }
  };

  return (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle>Webhook Integration</CardTitle>
        <CardDescription>Manage your Webhook integration here.</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center space-x-2">
            {intergrated ? (
              <FaCheckCircle className="w-5 h-5 text-green-600" />
            ) : (
              <FaTimesCircle className="w-5 h-5 text-red-500" />
            )}
            <Label>
              {intergrated
                ? "Webhook is configured."
                : "Webhook is not configured."}
            </Label>
          </div>

          <div className="flex flex-col gap-2 w-64 max-[350px]:w-auto">
            <Label htmlFor="event-type">Event Type</Label>
            <Select onValueChange={setSelectedEvent} value={selectedEvent}>
              <SelectTrigger id="event-type">
                <SelectValue placeholder="Select an event type" />
              </SelectTrigger>
              <SelectContent>
                {events.map((event) => (
                  <SelectItem key={event} value={event}>
                    {event}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-col gap-2 w-64 max-[350px]:w-auto">
            <Label htmlFor="webhook-url">Webhook URL</Label>
            <Input
              id="webhook-url"
              placeholder="Enter webhook URL"
              value={webhookUrl}
              onChange={(e) => setWebhookUrl(e.target.value)}
            />
          </div>

          <div className="flex justify-between items-center w-full max-[380px]:flex-col max-[380px]:gap-4 max-[380px]:items-start">
            <Button
              onClick={handleSave}
              disabled={!webhookUrl || !selectedEvent}
            >
              Save Webhook
            </Button>
            {intergrated && (
              <Button onClick={handleTest} variant="outline">
                Test Webhook
              </Button>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
