import { useContext, useEffect, useState } from "react";
import Logo from "./Logo";
import MainNav from "./MainNav";
import { AppLayoutContext } from "../pages/AppLayout";
import { GrContact } from "react-icons/gr";
import { IntercomProvider } from "react-use-intercom";
import IntercomButton from "./IntercomButton";

function Sidebar() {
  const {
    sidebarOpen,
    setSidebarOpen,
    sidebarHovered,
    setSidebarHovered,
    isMobile,
  } = useContext(AppLayoutContext);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const sidebarClass = `
    bg-white shadow-lg transition-all duration-300 ease-in-out
    ${isMobile ? "fixed top-0 left-0 h-full z-50" : "relative"}
    ${
      isMobile && sidebarOpen
        ? "w-60"
        : isMobile
        ? "w-0"
        : sidebarHovered
        ? "w-60"
        : "w-20 min-w-[4rem]"
    }
  `;

  const overlayClass = `
    fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out backdrop-blur-sm z-40
    ${
      (isSmallScreen || isMobile) && sidebarOpen
        ? "opacity-100 visible"
        : "opacity-0 invisible"
    }
  `;

  const buttonClass = `
    flex items-center w-full h-14 text-black bg-white rounded-lg hover:bg-black hover:text-white rounded-lg
    ${
      sidebarHovered || (isMobile && sidebarOpen)
        ? "justify-start px-5"
        : "justify-center"
    } 
  `;

  return (
    <IntercomProvider
      appId="uyosalr2"
      autoBoot={true}
      shouldInitialize={true}
      initializeDelay={0}
    >
      <div
        className={sidebarClass}
        onMouseEnter={() => !isMobile && setSidebarHovered(true)}
        onMouseLeave={() => !isMobile && setSidebarHovered(false)}
      >
        <div className="flex overflow-hidden flex-col justify-between p-4 h-full">
          <div>
            <div className="flex justify-between items-center mb-8 ml-[5px]">
              {(!isMobile || sidebarOpen) && (
                <Logo small={!sidebarHovered && !isMobile} />
              )}
            </div>
            <MainNav
              expanded={sidebarHovered || (isMobile && sidebarOpen)}
              isMobile={isMobile}
              sidebarOpen={sidebarOpen}
            />
          </div>
          <div className="flex flex-col gap-4">
            <IntercomButton
              buttonClass={buttonClass}
              sidebarHovered={sidebarHovered}
              isMobile={isMobile}
              sidebarOpen={sidebarOpen}
              expanded={sidebarHovered || (isMobile && sidebarOpen)}
            />
          </div>
        </div>
      </div>
      <div className={overlayClass} onClick={() => setSidebarOpen(false)}></div>
    </IntercomProvider>
  );
}

export default Sidebar;
