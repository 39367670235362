import React, { useEffect, useState } from "react";
import { Input } from "../../components/input";
import { Label } from "../../components/label";
import apiOrganization from "../../Dashborad/apiOrganization";
import apiPopups from "../WorkflowApis/apiPopups";

const Step1 = ({ workflowData, setWorkflowData, isEditMode }) => {
  const [popupId, setPopupId] = useState(null);
  const [initalAction, setInitalAction] = useState(null);
  const [initalActionType, setInitalActionType] = useState(null);

  const calculateInitalAction = () => {
    if (isEditMode) {
      if (workflowData.action.type === "popup") {
        setInitalActionType("popup");
      } else {
        setInitalAction(workflowData.action);
        setInitalActionType("agent");
      }
    } else {
      setInitalActionType("agent");
      setInitalAction({});
    }
  };

  useEffect(() => {
    calculateInitalAction();
  }, [isEditMode]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setWorkflowData((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleNotificationTypeChange = (type) => {
    if (!isEditMode && type === "popup") {
      setWorkflowData((prev) => ({
        ...prev,
        action: {
          type: "popup",
        },
      }));
    }
    if (!isEditMode && type === "agent") {
      setWorkflowData((prev) => ({
        ...prev,
        action: {},
      }));
    }

    if (isEditMode && type === "popup") {
      setWorkflowData((prev) => ({
        ...prev,
        action: {
          type: "popup",
        },
      }));
    }

    if (isEditMode && type === "agent") {
      if (initalActionType === "agent") {
        setWorkflowData((prev) => ({
          ...prev,
          action: initalAction,
        }));
      } else {
        setWorkflowData((prev) => ({
          ...prev,
          action: {},
        }));
      }
    }
  };

  useEffect(() => {
    const fetchPopupId = async () => {
      try {
        const data = await apiPopups();
        const filteredData = data.filter(
          (popup) => popup.type === "floating_popup"
        );
        if (filteredData.length > 0) {
          setPopupId(true);
        }
      } catch (error) {
        console.error("Error fetching popup id :", error);
      }
    };
    fetchPopupId();
  }, [setPopupId]);

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-semibold">Get Started</h2>
      <div className="space-y-2">
        <Label htmlFor="workflow-name">Name your workflow</Label>
        <Input
          id="workflow-name"
          placeholder="My First Workflow"
          value={workflowData.name}
          onChange={handleInputChange}
        />
      </div>
      <div className="space-y-2">
        <Label>Select a notification type</Label>
        <div className="space-y-2">
          <div
            className={`p-4 rounded-md border ${
              workflowData?.action?.type !== "popup" && "border-black"
            } cursor-pointer hover:bg-gray-50`}
            onClick={() => handleNotificationTypeChange("agent")}
          >
            <h3 className="font-semibold">Agent Notification</h3>
            <p className="text-sm text-gray-500">
              Notify the agent when a user matching certain criteria becomes
              active on a certain page.
            </p>
          </div>
          <div
            className={`p-4 border rounded-md  ${
              workflowData?.action?.type === "popup" && "border-black"
            }  hover:bg-gray-50 cursor-pointer ${
              popupId
                ? "opacity-100 pointer-events-auto"
                : "opacity-50 pointer-events-none"
            }`}
            onClick={() => popupId && handleNotificationTypeChange("popup")}
          >
            <h3 className="font-semibold">Visitor Popup</h3>
            <p className="text-sm text-gray-500">
              Display a popup to the end user when they are on a certain page
              and match certain criteria
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
