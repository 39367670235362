import toast from "react-hot-toast";

const apiWebhookTest = async () => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/integrations/webhook/test`,
      {
        method: "POST",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          org_id: org_id,
        }),
      }
    );

    if (response.status === 400) {
      toast.error("Webhook test failed: Invalid webhook url saved.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    if (error) {
      console.log(error);
    }
  }
};

export default apiWebhookTest;
