const apiVisitors = async (page, size, filters) => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  // Stringify filters only if filters is not undefined or null
  const filtersToString = filters ? JSON.stringify(filters) : "";

  // Construct the base URL
  let url = `${process.env.REACT_APP_API_URL}/organizations/${org_id}/visitors/get_filtered_visitors?page=${page}&size=${size}`;

  // Append filters to the URL if filters are provided
  if (filtersToString) {
    url += `&filters=${encodeURIComponent(filtersToString)}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "X-User-ID": userId,
        "X-API-Key": apiKey,
      },
    });

    if (!response.ok) {
      throw new Error("Fetching visitors failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching visitors:", error);
    throw error;
  }
};

export default apiVisitors;
