import StalkingOff from "./StalkingOff";
import { useF2FContext } from "../F2FContext";
import CallingWindow from "./CallingWindow";

export default function F2F() {
  const { online, stalking } = useF2FContext();

  if (online) {
    return (
      <>
        <div className={!stalking ? "hidden" : ""}>
          <CallingWindow />
        </div>
        <div className={stalking ? "hidden" : ""}>
          <StalkingOff />
        </div>
      </>
    );
  }

  if (!online) {
    return null;
  }
}
