import { useEffect, useState } from "react";
import apiOrganization from "../Dashborad/apiOrganization";
import Spinner from "../ui/Spinner";
import TeamTable from "../TeamPanel/TeamTable";
import InviteTeamatePopup from "../TeamPanel/inviteTeamatePopup";

function TeamPanel() {
  const [members, setMembers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [orgName, setOrgName] = useState(null);

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        setIsLoading(true);
        const data = await apiOrganization();
        setOrgName(data.organization.name);
        setMembers(data.organization.members);
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganization();
  }, []);

  const refetchMembers = async () => {
    try {
      const data = await apiOrganization();
      setMembers(data.organization.members);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <div>
          {/* <h3 className="pointer-events-none">{orgName}</h3> */}
          <p> {members && members.length} members</p>
        </div>
        <InviteTeamatePopup setMembers={setMembers} />
      </div>
      <div className="flex items-end">
        <TeamTable members={members} setMembers={refetchMembers} />
      </div>
    </div>
  );
}

export default TeamPanel;
