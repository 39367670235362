const apiLogin = async (loginDataOrToken, setLoading) => {
  setLoading(true); // Set loading to true when the request starts

  try {
    const isToken = typeof loginDataOrToken === "string"; // Check if the input is a token
    const body = isToken
      ? { token: loginDataOrToken } // If it's a token, send as { token }
      : loginDataOrToken; // Otherwise, use loginData

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const data = await response.json();
    console.log("Login data:", data);

    if (data?.user?.admin_key) {
      localStorage.setItem("adminkey", data.user.admin_key);
      localStorage.setItem("adminorg", data.user.org_id);
    }
    localStorage.setItem("agentName", data.user.name);
    localStorage.setItem("email", data.user.email);
    localStorage.setItem("apikey", data.user.api_key);
    localStorage.setItem("orgid", data.user.org_id);
    localStorage.setItem("userid", data.user._id);
    localStorage.setItem("user_created_at", data.user.created_at);

    return { success: true, data }; // Return success and data
  } catch (error) {
    console.error("Error during login:", error);
    return { success: false, error }; // Return failure and error message
  } finally {
    setLoading(false); // Set loading to false when the request ends
  }
};

export default apiLogin;
