const apiWebhookEvents = async () => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/integrations/webhook/events`,
      {
        method: "GET",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Fetching webhook events failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error when fetching webhook events :", error);
    throw error;
  }
};

export default apiWebhookEvents;
