import React, { useEffect, useState } from "react";
import { Card, CardContent } from "../../components/card";
import { Button } from "../../components/button";
import apiPopups from "../WorkflowApis/apiPopups";
import { useNavigate } from "react-router-dom";
import Spinner from "../../ui/Spinner";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/dropdown-menu";
import { FiEdit, FiMoreVertical } from "react-icons/fi";
import { Switch } from "../../components/switch";

export default function Step2({ workflowData, setWorkflowData, isEditMode }) {
  const [popups, setPopups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPopup, setSelectedPopup] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPopups = async () => {
      try {
        const data = await apiPopups();
        const filteredData = data.filter(
          (popup) => popup.type === "floating_popup"
        );

        // Find default popup (without a name) and ensure it's set to workflow
        const defaultPopup = filteredData.find((popup) => !popup.name);
        if (defaultPopup) {
          setWorkflowData((prevData) => ({
            ...prevData,
            action: { ...prevData.action, popup_id: defaultPopup._id },
          }));
          setSelectedPopup(defaultPopup._id);
        }

        if (isEditMode && workflowData.action && workflowData.action.popup_id) {
          setSelectedPopup(workflowData.action.popup_id);
        }

        // Sort the popups array with the default popup at the start
        const sortedPopups = [
          ...(defaultPopup ? [defaultPopup] : []),
          ...filteredData.filter((popup) => popup !== defaultPopup),
        ];

        setPopups(sortedPopups);
      } catch (error) {
        console.error("Error fetching popup ids:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPopups();
  }, []);

  const handleEdit = (id) => {
    navigate(`/settings/popup?editpopup=${id}`);
  };

  const handleCardClick = (popupId) => {
    setSelectedPopup(popupId);
    setWorkflowData((prevData) => ({
      ...prevData,
      action: { ...prevData.action, popup_id: popupId },
    }));
  };

  const handleAddPopup = () => {
    navigate(`/settings/popup?newpopup=true`);
  };

  const handleToggle = (popupId) => {
    setWorkflowData((prevData) => {
      const newAction = { ...prevData.action };
      if (newAction.hide_toggle === undefined) {
        newAction.hide_toggle = true;
      } else {
        newAction.hide_toggle = !newAction.hide_toggle;
      }
      return { ...prevData, action: newAction };
    });
  };

  return (
    <div className="space-y-6 w-full max-w-3xl">
      <div className="space-y-2">
        <h3 className="text-muted-foreground">
          Select which floating popup you&apos;d like to use
        </h3>
      </div>

      <div className="space-y-4">
        {isLoading ? (
          <Spinner />
        ) : (
          popups.map((popup) => (
            <Card
              key={popup.id}
              className={`shadow-none transition-colors cursor-pointer hover:bg-accent/50 ${
                selectedPopup === popup._id
                  ? "bg-accent border-2 border-primary"
                  : ""
              }`}
              onClick={() => handleCardClick(popup._id)}
            >
              <CardContent className="flex justify-between items-center p-4">
                <div>
                  <p className="text-[13px] text-muted-foreground">
                    {popup.name ? "Custom Popup" : "Default Popup"}
                  </p>
                  <p className="font-semibold text-md">
                    {popup.name || "Default Popup"}
                  </p>
                </div>

                {selectedPopup === popup._id && (
                  <div className="flex items-center space-x-2">
                    <Switch
                      id={`hide-toggle-${popup._id}`}
                      checked={workflowData.action?.hide_toggle ?? false}
                      onCheckedChange={() => handleToggle(popup._id)}
                    />
                    <label
                      htmlFor={`hide-toggle-${popup._id}`}
                      className="text-sm cursor-pointer text-muted-foreground"
                    >
                      Hide Toggle
                    </label>
                  </div>
                )}

                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="p-0 w-8 h-8">
                      <FiMoreVertical className="w-4 h-4" />
                      <span className="sr-only">Open menu</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={(e) => handleEdit(popup._id, e)}>
                      <FiEdit className="mr-2 w-4 h-4" />
                      <span>Edit</span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </CardContent>
            </Card>
          ))
        )}

        <Card className="border-green-300 shadow-none transition-colors cursor-pointer hover:bg-green-500/90 bg-green-500/80">
          <CardContent className="p-4 text-center">
            <button onClick={handleAddPopup}>
              <h2 className="font-semibold text-black/80 text-md">
                Add new Popup
              </h2>
            </button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
