import {
  DailyVideo,
  useParticipantIds,
  useParticipantProperty,
} from "@daily-co/daily-react";
import React, { useEffect } from "react";
import { PiSwap } from "react-icons/pi";
import { useF2FContext } from "../../F2FContext";

export default function VisitorsScrShare() {
  const { setScreenShareOn } = useF2FContext();

  const remoteParticipantIds = useParticipantIds({ filter: "remote" });

  const id = remoteParticipantIds[0];

  const screenSharingTrack = useParticipantProperty(id, "screenVideoTrack");

  //this is if visitor shuts their scr share but we have it on the big screen so it switches to iframe
  useEffect(() => {
    if (!screenSharingTrack) {
      setScreenShareOn(false);
    }
  }, [screenSharingTrack, setScreenShareOn]);

  return screenSharingTrack ? (
    <React.Fragment key={id}>
      <div
        onClick={() => setScreenShareOn((prev) => !prev)}
        className="relative w-auto h-auto rounded-lg border border-gray-100 shadow-lg cursor-pointer shadow-gray-200 group"
      >
        {/* Hover overlay */}
        <div className="absolute inset-0 bg-black rounded-lg opacity-0 transition-opacity duration-300 group-hover:opacity-50"></div>

        {/* Icon */}
        <PiSwap className="absolute inset-0 m-auto text-4xl text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100" />

        {/* Content */}
        <div className="rounded-lg aspect-w-16 aspect-h-9">
          <DailyVideo
            type="screenVideo"
            fit="contain"
            sessionId={id}
            className="object-contain w-full h-full rounded-md"
          />
        </div>
      </div>
    </React.Fragment>
  ) : null;
}
