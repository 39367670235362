import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { GrContact } from "react-icons/gr";
import apiOrganization from "../Dashborad/apiOrganization";

function IntercomButton({
  buttonClass,
  sidebarHovered,
  isMobile,
  sidebarOpen,
  expanded,
}) {
  const { boot, show, hide, update } = useIntercom();
  const [orgData, setOrgData] = useState();

  useEffect(() => {
    async function fetchOrgData() {
      try {
        const data = await apiOrganization();
        setOrgData(data.organization);
      } catch (error) {
        console.error(error);
      }
    }

    fetchOrgData();
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("userid");
    const agentName = localStorage.getItem("agentName");
    const agentEmail = localStorage.getItem("email");
    const user_created_at = localStorage.getItem("user_created_at");

    // Boot Intercom on initial load
    if (orgData) {
      boot({
        userId,
        name: `${agentName} - ${orgData.name}`,
        email: agentEmail,
        createdAt: user_created_at,
        hideDefaultLauncher: true,
        ...orgData, // Spread the orgData here
      });

      // Hide the Intercom messenger after booting
      hide();

      // Update Intercom settings to hide the default launcher
      update({ hideDefaultLauncher: true });
    }
  }, [boot, hide, update, orgData]);

  const toggleIntercom = () => {
    show();
  };

  return (
    <button className={buttonClass} onClick={toggleIntercom}>
      {(expanded || !isMobile) && (
        <span className="flex flex-shrink-0 justify-center items-center w-5 h-5">
          <GrContact className="w-5 h-5" />
        </span>
      )}
      <span
        className={`ml-3 text-lg whitespace-nowrap ${
          sidebarHovered || (isMobile && sidebarOpen)
            ? "opacity-100 w-auto"
            : "opacity-0 hidden w-0"
        } transition-all duration-300`}
      >
        Contact us
      </span>
    </button>
  );
}

export default IntercomButton;
