const apiAcceptInvite = async (invitation_id) => {
  const userId = localStorage.getItem("userid");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/invitations/${invitation_id}/accept`,
      {
        method: "POST",
        headers: {
          "X-User-ID": userId,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Accepting invitation failed");
    }
    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
      localStorage.setItem("apikey", data.api_key);
      localStorage.setItem("orgid", data.org_id);
      return data;
    }
    return null;
  } catch (error) {
    console.error("Error Accepting invitation:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export default apiAcceptInvite;
